export default {
  "buttonProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "buttonContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "designerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ux Ui Designer"])},
  "devText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégratrice."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis là pour t'accompagner dans la création ou la refonte d'interfaces web et pour t'aider à donner vie à tes projets."])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
  "filtertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je m'appelle Betsabé, mon travail combine expérience utilisateur, conception visuelle et développement frontend."])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir travaillé pendant plusieurs années en tant qu'architecte, j'ai réorienté ma carrière vers une nouvelle passion : la création de produits numériques."])},
  "aboutP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon parcours professionnel m'a permis d'acquérir une grande sensibilité aux détails visuels, ainsi qu'une grande curiosité pour les aspects techniques. C'est pourquoi le développement frontend m'intéresse particulièrement."])},
  "aboutP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je travaille actuellement en freelance et je suis à la recherche d'un contrat permanent. Si tu souhaites travailler avec moi, n'hésite pas à me contacter."])},
  "happy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le design thinking comme outil de résolution des problèmes."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Happy life est un projet qui visait à comprendre et à trouver des solutions innovantes aux problèmes d'isolement social résultant de la pandémie de Covid 19. À cette fin, une recherche sur les utilisateurs et différents exercices de réflexion ont été réalisés en utilisant le design thinking comme instrument."])}
  },
  "figy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conception d'une marketplace app pour la vente de produits phygitaux et écologiques."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGY est une application mobile, conçue comme projet de fin d'études pour le Mastère en expérience utilisateur d'Elisava. Il s'agit d'une plateforme disruptive qui propose des produits écologiques associés à un NFT, garantissant leur traçabilité."])}
  },
  "wand": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conception d'une application domotique pour le contrôle des appareils connectés de la maison."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wand est un projet qui consistait en la création d'une application pour une maison intelligente. L'objectif du client était de centraliser l'utilisation de tous les objets connectés d'une maison, même s'ils appartiennent à des marques différentes, dans une seule application."])}
  },
  "groupomania": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un réseau social d'entreprise pour améliorer la satisfaction des employés."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet accompli dans le cadre de la formation de développeur web chez Openclassrooms, en France. Le réseau social interne du groupe Groupomania, permet de créer un compte utilisateur, de se connecter et de participer au forum interne de l'entreprise."])}
  },
  "hot": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une API pour une application web de sauces piquantes."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une API pour une application web où les utilisateurs peuvent recommander leurs sauces piquantes préférées et également noter les sauces postées par d'autres utilisateurs."])}
  },
  "csm": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défi d'intégration web, créé par le site web de frontend practice."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défi d'intégration web, proposé par le site frontendpractice.com. L'exercice consistait à recréer la page d'accueil de canal street market, en prenant en compte le style graphique, les couleurs, la typographie, les animations, etc. La maquette est également responsive"])}
  },
  "cv": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de thème personnalisé sur Wordpress."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog créé avec wordpress. Le projet consiste en la création et l'intégration d'un thème personnalisé pour wordpress, la création d'une base de données, et sa mise en ligne."])}
  },
  "coa": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom website development"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website created with WordPress and Elementor Pro. It consists of building a manageable website for a new brand of protein chocolates. Design by:"])}
  },
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["télécharger cv"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte-moi !"])}
}