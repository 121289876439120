export default {
  "buttonProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mí"])},
  "buttonContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
  "designerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño Ux Ui"])},
  "devText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo web."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi objetivo principal es ayudarte a hacer realidad tus proyectos digitales. Me especializo en la creación o rediseño de aplicaciones y sitios web que sean funcionales y atractivos para tus usuarios."])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyectos"])},
  "filtertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mí"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola, soy Betsabé. Mi trabajo combina la experiencia de usuario, el diseño visual y el desarrollo frontend."])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitecta de profesión, en los últimos años he explorado y cultivado mi nueva pasión: el diseño de productos digitales. Al igual que en la arquitectura, mi interés se centra en crear productos que no solo sean visualmente atractivos, sino también útiles y que, de alguna manera, contribuyan a mejorar la vida de las personas."])},
  "aboutP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi experiencia en el campo del diseño me ha proporcionado una sensibilidad hacia los detalles visuales y una profunda curiosidad por los aspectos técnicos. Es por eso que, además de enfocarme en la parte visual, disfruto comprender los aspectos técnicos de los proyectos en los que trabajo. Esta habilidad me ha permitido mejorar mi comunicación con profesionales de diversas áreas."])},
  "aboutP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Actualmente, compagino mi labor como arquitecta con proyectos de diseño web en modo freelance, sin embargo, mi deseo es enfocarme completamente en el diseño y desarrollo digital. Por esta razón, estoy buscando colaboraciones y nuevas oportunidades laborales."])},
  "happy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design thinking como una herramienta para resolver los problemas actuales."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HappyLife es un proyecto que tiene como objetivo encontrar una solución al problema del aislamiento social, utilizando herramientas del design thinking."])}
  },
  "figy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de un marketplace para la venta de productos phygitales y ecológicos"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGY es una aplicación móvil, diseñada como proyecto final para el Máster en Experiencia de Usuario de Elisava, en España. Es una plataforma disruptiva que ofrece productos sostenibles asociados a un NFT, garantizando su trazabilidad."])}
  },
  "wand": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de una aplicación domótica para controlar los dispositivos conectados del hogar."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este proyecto consistió en el diseño UI y UX de una aplicación para viviendas inteligentes. Incluyó investigación con usuarios, pruebas de usabilidad, diseño de arquitectura web, prototipos y diseño final de la interfaz. Este trabajo se llevó a cabo en un período de 4 meses durante el Máster en Diseño de Experiencia de la escuela ELISAVA."])}
  },
  "groupomania": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de una red social corporativa para mejorar la satisfacción de los empleados."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto completado durante el programa de formación de desarrollo web en Openclassrooms, en Francia. La red social interna del grupo Groupomania permite crear una cuenta de usuario, conectarse y participar en el foro interno de la empresa."])}
  },
  "hot": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de una API para una aplicación web que permite valorar salsas picantes."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de una interfaz de programación de aplicaciones (API) para una aplicación web que permite a los usuarios recomendar sus salsas picantes preferidas y evaluar las selecciones de otros usuarios."])}
  },
  "csm": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafío de integración web, creado por el sitio web de práctica de frontend."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafío de integración web, creado por el sitio web de práctica de frontend. El ejercicio consistió en recrear la página de inicio de Canal Street Market, teniendo en cuenta el estilo gráfico, colores, tipografía, animaciones, etc. El diseño también es responsivo."])}
  },
  "cv": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de un tema personalizado en Wordpress."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog creado con WordPress. El proyecto consiste en la creación e integración de un tema personalizado para WordPress, la creación de una base de datos y su publicación."])}
  },
  "coa": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de un sitio web personalizado."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web creado con WordPress y Elementor pro. Consiste en la construccion del sitio web administrable, para una nueva marca de chocolates proteicos. Diseño realizado por:"])}
  },
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar cv"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])}
}